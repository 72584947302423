@font-face {
  font-family: "Gilroy";
  src: url("../font/gilroy/Extrabold.otf");
  font-weight: 800; }

@font-face {
  font-family: "Gilroy";
  src: url("../font/gilroy/Medium.otf");
  font-weight: 500; }

@font-face {
  font-family: "Gilroy";
  src: url("../font/gilroy/Light.otf");
  font-weight: 300; }

@media (max-width: 991px) {
  .for-mobile {
    flex-direction: column-reverse;
    text-align: center; }
    .for-mobile img {
      margin-bottom: 1rem; } }

body {
  font-family: "Gilroy";
  color: #1d1d1b; }

.text-uppercase {
  letter-spacing: 1px; }

.text-bold {
  font-weight: 800; }

.color-orange {
  color: #ff9d00 !important; }

.color-green {
  color: #2da82d !important; }

.btn-outline-green {
  color: #2da82d !important;
  border-color: #2da82d;
  border-width: 2px !important;
  min-width: 8rem;
  font-weight: 700; }
  .btn-outline-green:hover {
    border-color: #2da82d;
    background-color: #2da82d;
    color: white !important; }

.navbar-light {
  border-bottom: 1px solid #dce3eb;
  background-color: white !important; }
  .navbar-light .nav-item {
    margin-left: 1rem; }
    .navbar-light .nav-item .nav-link {
      color: #6d7278; }

.text-green {
  color: #2da82d; }

.text-red {
  color: #fb3640; }

.bg-blue {
  background-color: #1264a3; }

.bg-lightblue {
  background-color: #f8fbfe; }

.input-warning {
  border: 1px solid #fb3640 !important; }

.btn-green {
  background-color: #2da82d;
  border-color: #2da82d;
  color: white !important;
  border-width: 2px !important;
  min-width: 8rem;
  font-weight: 700; }
  .btn-green:hover {
    opacity: 0.8; }

.btn-orange {
  background-color: #ff9d00;
  border-color: #ff9d00;
  color: white !important;
  border-width: 2px !important;
  min-width: 8rem;
  font-weight: 700; }
  .btn-orange:hover {
    opacity: 0.8; }

.other-page-header {
  padding: 150px 0; }

.request-form {
  background-color: #fff;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
  padding: 2rem; }

.form-control {
  padding: 0.5rem 0.75rem;
  border: 1px solid #dce3eb; }

.main-header {
  background-color: #f8fbfe;
  background-image: url("../img/bg-header.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  padding: 150px 0 300px 0; }
  @media (max-width: 991px) {
    .main-header {
      padding: 150px 0 100px 0; } }
  .main-header .header-content .sub-title {
    font-size: 18px;
    font-weight: 500; }
  .main-header .header-content h1 {
    font-size: 48px;
    font-weight: 800; }
  .main-header .header-content ul {
    margin-top: 1.5rem; }
    .main-header .header-content ul li {
      margin-bottom: 1rem;
      font-weight: 300;
      font-size: 18px;
      color: #6d7278; }

.mobile-number {
  margin-left: 0.5rem; }

.about-section {
  background-color: #f8fbfe; }
  .about-section .about-box {
    border: 1px solid #dce3eb;
    border-radius: 5px;
    background-color: white;
    padding: 2rem 1.5rem;
    margin: 1rem 0;
    min-height: 200px; }
    .about-section .about-box .box-title {
      display: flex;
      flex-direction: row;
      align-items: center; }
      .about-section .about-box .box-title .title-icon {
        position: relative;
        min-width: 45px; }
        .about-section .about-box .box-title .title-icon::after {
          content: "";
          width: 40px;
          height: 40px;
          background-color: #28ace8;
          opacity: 0.2;
          border-radius: 50%;
          position: absolute;
          top: -0.5rem;
          left: -0.5rem; }
      .about-section .about-box .box-title .title-self {
        font-size: 18px;
        font-weight: 500;
        margin-left: 0.5rem; }
    .about-section .about-box .box-descr {
      margin-top: 1rem;
      font-weight: 300;
      color: #6d7278;
      margin-left: 3.5rem; }

.image-box {
  position: relative;
  padding: 40px 0; }
  .image-box::after {
    content: "";
    width: 90%;
    height: 100%;
    position: absolute;
    border: 2px solid #ff9d00;
    border-radius: 15px;
    left: 20%;
    top: 0;
    z-index: -1; }
  .image-box img {
    position: absolute;
    left: 0; }

@media (max-width: 991px) {
  .for-whom-tabs {
    flex-wrap: nowrap; } }

.for-whom-tabs button {
  margin-right: 1rem; }
  @media (max-width: 991px) {
    .for-whom-tabs button {
      font-size: 1rem !important; } }

.for-whom-tabs .active {
  color: white;
  background-color: #1264a3; }

.tab-list-items {
  margin-top: 2rem; }
  .tab-list-items li {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    font-weight: 300;
    color: #6d7278; }
    .tab-list-items li .tab-list-icon {
      margin-right: 0.5rem;
      color: #2da82d;
      position: relative; }
      .tab-list-items li .tab-list-icon i {
        font-size: 18px; }
      .tab-list-items li .tab-list-icon::after {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: #ff9d00;
        opacity: 0.2;
        left: -0.2rem;
        top: -0.1rem;
        border-radius: 50%; }

.tab-title {
  font-size: 18px;
  font-weight: 500; }

.app-success-section .success-image-box {
  position: relative; }
  @media (max-width: 991px) {
    .app-success-section .success-image-box::after {
      content: none !important; } }
  .app-success-section .success-image-box::after {
    content: "";
    width: 90%;
    height: 100%;
    position: absolute;
    border: 2px solid #2da82d;
    border-radius: 15px;
    left: -10%;
    top: 0;
    z-index: -1; }

.link-with-arrow {
  text-decoration: none;
  color: #1264a3; }
  .link-with-arrow:hover {
    text-decoration: underline;
    color: #1264a3; }

.benefits-first-box .first-box-icon {
  position: relative;
  color: #1264a3; }
  .benefits-first-box .first-box-icon::after {
    content: "";
    position: absolute;
    width: 45px;
    height: 45px;
    background-color: #2da82d;
    opacity: 0.2;
    left: -0.5rem;
    top: -0.5rem;
    border-radius: 50%; }

.benefits-first-box .first-box-title {
  font-weight: 800;
  font-size: 18px;
  padding: 1rem 0;
  color: #1264a3; }

.benefits-first-box .first-box-descr {
  font-weight: 300; }

.benefits-box {
  border: 1px solid #dce3eb;
  background-color: white;
  border-radius: 5px;
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  min-height: 13rem; }
  .benefits-box .box-title i {
    position: relative; }
    .benefits-box .box-title i::after {
      content: "";
      position: absolute;
      width: 18px;
      height: 18px;
      background-color: #2da82d;
      opacity: 0.2;
      left: -0.2rem;
      top: -0.2rem;
      border-radius: 50%; }
  .benefits-box .box-descr {
    color: #6d7278;
    font-weight: 300;
    margin-top: 1rem; }

.contact-us-section {
  background-image: url("../img/contact.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 100px 0;
  position: relative; }
  .contact-us-section::before {
    content: "";
    position: absolute;
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }

.text-big {
  font-size: 3rem; }
  @media (max-width: 991px) {
    .text-big {
      font-size: 2rem; } }

.btn-white {
  background-color: white; }
  .btn-white:hover {
    opacity: 0.8; }

.btn-white-outlined {
  border-color: white;
  color: white; }
  .btn-white-outlined:hover {
    background-color: white; }

footer .navbar-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around; }
  footer .navbar-footer .nav-link {
    color: #1d1d1b !important;
    font-weight: 500; }

footer .footer-soc-net {
  display: flex;
  justify-content: space-between; }
  footer .footer-soc-net a {
    border: 1px solid #6d7278;
    border-radius: 50%;
    position: relative;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none; }
    footer .footer-soc-net a:hover {
      background-color: #1264a3;
      color: #fff !important;
      border-color: #1264a3; }

footer .footer-list-items li {
  margin-bottom: 0.5rem; }

footer .store-buttons a {
  width: 200px;
  margin-right: 1rem; }

.text-blue {
  color: #1264a3 !important; }

.blue-link {
  color: #1264a3;
  text-decoration: none;
  position: relative; }
  .blue-link::before {
    content: "";
    position: absolute;
    border-bottom: 2px solid #1264a3;
    bottom: -2px;
    transition: width 420ms cubic-bezier(0.165, 0.84, 0.44, 1);
    width: 0; }
  .blue-link:hover {
    color: #1264a3; }
    .blue-link:hover::before {
      width: 100%; }

.white-link {
  color: #fff;
  text-decoration: none;
  position: relative; }
  .white-link::before {
    content: "";
    position: absolute;
    border-bottom: 2px solid #fff;
    bottom: -2px;
    transition: width 420ms cubic-bezier(0.165, 0.84, 0.44, 1);
    width: 0; }
  .white-link:hover {
    color: #fff; }
    .white-link:hover::before {
      width: 100%; }

.letter-spacing {
  letter-spacing: 0.8px; }

.blue-link-with-arrow {
  color: #1264a3;
  text-decoration: none;
  position: relative; }
  .blue-link-with-arrow::after {
    font-family: "Font Awesome 5 Pro";
    content: "\f178";
    font-weight: 100;
    margin-left: 0.4rem; }
  .blue-link-with-arrow::before {
    content: "";
    position: absolute;
    border-bottom: 2px solid #1264a3;
    bottom: -2px;
    transition: width 420ms cubic-bezier(0.165, 0.84, 0.44, 1);
    width: 0; }
  .blue-link-with-arrow:hover {
    color: #1264a3; }
    .blue-link-with-arrow:hover::before {
      width: 100%; }

.msg-output {
  height: 2rem; }

.bg-blue-with-shadow {
  background-color: #1264a3;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1); }
